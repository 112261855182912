<template>
  <AccountDropdown class="btn-primary" :label="name" :actions="actions" />
</template>

<script>

import rolesMixin from '../../../mixins/roles';
export default {
  name: 'account-account-dropdown',
  mixins: [rolesMixin],
  components: {
    AccountDropdown: () => import('../../../libs/OAuth2/components/AccountDropdown'),
  },
  data () {
    return {
      actions: [
        { key: 'profile', link: { name: 'profile' }, label: 'Profilo' },
        { key: 'change-password', link: { name: 'change-password' }, label: 'Cambia password' },
        { key: 'logout', link: { name: 'logout' }, label: 'Logout' },
      ],
    };
  },
  computed: {
    name () {
      let text = this.$oauth2?.me?.name;
      text += ' ';
      if (this.isAdmin) {
        text += '(Amministratore)';
      } else if (this.isOperator) {
        text += '(Operatore)';
      }

      return text;
    },
  },
};

</script>
